<template>
  <v-container>
    <div class="text-center mt-12" v-if="loading">
      <v-progress-circular size="60" indeterminate color="primary" />
    </div>
    <center v-if="!loading">
      <v-card width="450" class="justify-center text-center mt-8 mb-8">
        <div class="px-3 px-md-10 py-8">
          <v-card-title class="text-uppercase justify-center">
            Recuperar Senha
          </v-card-title>
          <v-divider></v-divider>
          <v-row v-if="loadingLinear">
            <v-col cols="12">
              <v-progress-linear indeterminate color="secondary"></v-progress-linear>
            </v-col>
          </v-row>
          <p class="text-14 mb-1 text-left mt-4">Email</p>
          <v-text-field outlined dense hide-details class="mb-4" placeholder="Email registrado na loja" v-model="user.email" :disabled="loadingLinear" :rules="emailValidationRules"></v-text-field>

          <v-btn block color="secondary" :disabled="loadingLinear" class="text-uppercase font-600 transicao-btn" @click="showDialogValidateEmail()">
            VALIDAR CÓDIGO
          </v-btn>

          <v-alert style="color: red" dismissible transition="scale-transition" outlined dense v-if="errorEmailNotFound != null" type="error">
            {{ errorEmailNotFound }}
          </v-alert>
        </div>
      </v-card>
    </center>

    <v-dialog name="dialog-logout-sucesso" v-model="dialogSucessoResetPassword" max-width="300" persistent>
      <v-card>
        <v-card-text class="text-center">
          <v-icon color="success" class="mt-2" size="50">mdi-check-circle</v-icon>
          <h6 class="mt-2 text-uppercase">Senha alterada com sucesso!</h6>
          <!-- <v-row>
            <v-col cols="12">
              <v-progress-linear indeterminate color="secondary">
              </v-progress-linear>
              <span> Carregando dados...</span>
            </v-col>
          </v-row> -->
          <span>
            Clique em 'Iniciar sessão' para entrar na tua conta informando o
            email/tetefone e a nova senha.
          </span>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn color="success" outlined :to="{ name: 'PortalLogin' }">
            <v-icon>mdi-login</v-icon> INICIAR SESSÃO</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog name="dialog-validate-email" v-model="dialogValidateEmail" max-width="380" persistent>
      <v-card>
        <v-card-text class="text-center">
          <v-icon color="warning" class="mt-2" size="70">mdi-check-circle</v-icon>
          <div class="mt-2">
            <!--   -->
            <v-row v-if="loadingDialog">
              <v-col cols="12">
                <v-progress-linear indeterminate color="secondary"></v-progress-linear>
              </v-col>
            </v-row>
            <span class="text-subtitle1">
              Para validar a recuperação da senha, digite o código de
              <span class="font-weight-bold">6 dígitos </span> que enviamos no
              seguinte email:
              <span class="font-weight-bold">{{ user.email }}</span>
            </span>
            <p class="text-14 mb-1 text-left mt-4">Código de Validação</p>
            <v-text-field maxlength="6" outlined v-model="user.codigo" dense></v-text-field>
            <v-alert style="color: red" dismissible transition="scale-transition" outlined dense v-if="errorCodeNotFound != null" type="error">
              {{ errorCodeNotFound }}
            </v-alert>
            <p class="text-14 mb-1 text-left mt-4">Nova senha</p>
            <v-text-field outlined dense hide-details class="mb-4" placeholder="Nova senha" v-model="user.password1" :disabled="loadingLinear" :append-icon="showpassword ? 'mdi-eye-off' : 'mdi-eye'" :type="showpassword ? 'text' : 'password'" @click:append="showpassword = !showpassword" @input="validarSenha"></v-text-field>

            <p class="text-14 mb-1 text-left mt-4">Repetir senha</p>
            <v-text-field outlined dense hide-details class="mb-4" placeholder="Repetir senha" v-model="user.password2" :disabled="loadingLinear" :append-icon="showpassword ? 'mdi-eye-off' : 'mdi-eye'" :type="showpassword ? 'text' : 'password'" @click:append="showpassword = !showpassword" @input="validarSenha"></v-text-field>

            <v-col>
              <p v-if="countdown > 0">
                Tempo restante:
                <span class="font-weight-bold">
                  {{ formatTime(countdown) }}
                </span>
              </p>
              <!-- <span>
                caso não tenha recebido o código no teu email, queira solicitar
                novamente!
              </span> -->
            </v-col>
          </div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn @click="requestAnother" :disabled="countdown != 0" dense outlined text>
            SOLICITAR OUTRO
          </v-btn>
          <v-btn color="green" :disabled="checkPassword()" class="text-uppercase white--text ml-2" @click="validarEmailResetSenha()" dense>
            SALVAR NOVA SENHA
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog name="dialog-new-password" v-model="dialogNewPassword" max-width="350" persistent>
      <v-card width="550" class="justify-center text-center">
        <div class="px-3 px-md-10 py-8">
          <v-card-title class="text-uppercase justify-center">
            Nova Senha
          </v-card-title>
          <v-divider></v-divider>
          <v-row v-if="loadingLinear">
            <v-col cols="12">
              <v-progress-linear indeterminate color="secondary"></v-progress-linear>
            </v-col>
          </v-row>

          <p class="text-14 mb-1 text-left mt-4">Código de Validação</p>
          <v-text-field maxlength="6" input-class="custom-font-size" outlined v-model="user.codigo" dense id="id"></v-text-field>

          <p class="text-14 mb-1 text-left mt-4">Nova senha</p>
          <v-text-field outlined dense hide-details class="mb-4" placeholder="Nova senha" v-model="user.email" :disabled="loadingLinear"></v-text-field>

          <p class="text-14 mb-1 text-left mt-4">Repetir senha</p>
          <v-text-field outlined dense hide-details class="mb-4" placeholder="Repetir senha" v-model="user.email" :disabled="loadingLinear"></v-text-field>

          <v-btn block color="secondary" :disabled="loadingLinear" class="text-uppercase font-600 transicao-btn" @click="setNewPassword()">
            Alterar senha
          </v-btn>

          <v-alert style="color: red" dismissible transition="scale-transition" outlined dense v-if="errorEmailNotFound != null" type="error">
            {{ errorEmailNotFound }}
          </v-alert>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { LOGIN_URL, BASE_URL } from "@/configs/api";

export default {
  data: () => ({
    errorCodeNotFound: null,
    loadingDialog: false,
    dialogNewPassword: false,
    loadingLinear: false,
    errorEmailNotFound: null,
    countingDown: false,
    countdown: 120, // 2 minutos em segundos
    email: "",
    code: "",
    numbers: Array(6).fill(null),
    dialogValidateEmail: false,
    dialogSucessoResetPassword: false,
    senhaValida: "",
    showpassword: false,
    emailValidationRules: [
      (val) => !!val || "Email é obrigatório",
      (val) => /.+@.+\..+/.test(val) || "Email inválido",
    ],
    step: 1,
    user: {
      name: "",
      telefone: "",
      email: "",
      password1: "",
      password2: "",
    },
    dialogResetPassword: false,
    overlay: false,
    dialog: false,
    showpassword: false,
    valid: true,
    alert: {
      text: "",
      type: "",
      text2: "",
      type2: "",
    },
    utilizador: {},
    user_roles: [],
    roles: {},
    sobrenome: "",
    dialogTermo: true,
    aceitar_termo: false,
    loading: false,
  }),
  computed: {},
  created() {
    this.startCountdown(); // Inicia o contador automaticamente ao criar o componente
  },
  mounted() {},
  methods: {
    showDialogValidateEmail() {
      this.sendEmailCode();
    },
    validarEmailResetSenha() {
      this.loadingDialog = true;
      this.errorCodeNotFound = null;
      let url = `${BASE_URL}/user/novaSenhaMv`;
      axios
        .post(url, {
          codigo: this.user.codigo,
          password1: this.user.password1,
          password2: this.user.password2,
        })
        .then((response) => {
          this.loadingDialog = false;
          if (response.data.message != null) {
            this.dialogSucessoResetPassword = true;
            // alert(JSON.stringify(response.data))
            // console.log(response.data);
          }
        })
        .catch((error) => {
          this.loadingDialog = false;
          if (error.response) {
            // console.log("Erro de resposta do servidor:");
            // console.log("Status:", error.response.status);
            // this.dialogValidateEmail = false;
            this.errorCodeNotFound = error.response.data.message;
            // this.alert.type = "error";
          }
        });
    },
    sendEmailCode() {
      this.loadingLinear = true;
      this.errorEmailNotFound = null;
      let url = `${BASE_URL}/user/recuperarSenhaMv`;
      axios
        .post(url, {
          email: this.user.email,
        })
        .then((response) => {
          this.loadingLinear = false;
          console.log(response.data);
          if (response.data.message != null) {
            this.dialogValidateEmail = true;
          }
        })
        .catch((error) => {
          this.loadingLinear = false;
          if (error.response) {
            // console.log("Erro de resposta do servidor:");
            // console.log("Status:", error.response.status);
            // console.log("Message:", error.response.data.message);
            this.dialogValidateEmail = false;
            this.errorEmailNotFound = error.response.data.message;
            this.alert.type = "error";
          }
        });
    },

    startCountdown() {
      this.countingDown = true;
      const countdownInterval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(countdownInterval);
        }
      }, 1000);
    },
    onDialogClose(value) {
      if (!value) {
        // Resetar o contador quando o diálogo for fechado
        this.countingDown = false;
        this.countdown = 120;
      }
    },
    requestAnother() {
      this.countdown = 120; // Reiniciar o contador
      this.startCountdown(); // Iniciar o contador novamente
      this.sendEmailCode();
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
    },
    validarSenha() {
      const senha = this.user.password1;
      const senhaValida =
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(senha);
      this.senhaValida = senhaValida;
    },
    checkPassword() {
      let password1 = this.user.password1;
      let password2 = this.user.password2;
      return (
        password1 != password2 &&
        password1.length < 8 &&
        password2.length < 8 &&
        password1 != "" &&
        password2 != ""
      );
    },

    setNewPassword() {
      this.loadingLinear = true;
      let url = `${BASE_URL}/novaSenhaMv`;
      axios
        .post(url, {
          codigo: this.user.codigo,
          password1: this.user.password1,
          password2: this.user.password2,
        })
        .then((response) => {
          console.log("SUCESSO: " + response.data);
          if (response.data.message) {
            setTimeout(() => {
              this.loading = false;
            }, 2000);

            setTimeout(() => {
              this.dialogValidateEmail = false;
              this.dialogSucessoResetPassword = true;
            }, 2500);
            setTimeout(() => {
              this.dialogSucessoResetPassword = false;
            }, 6000);
          }
        })
        .catch((error) => {
          // console.log("ERRO: " + error);
          this.alert.text = "Não foi possivel establecer ligação!";
        });
    },
    invalidEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.email)) {
        return true;
      } else {
        return false;
      }
    },

    async login() {
      await axios.get(`${LOGIN_URL}/sanctum/csrf-cookie`);
      let url = `${LOGIN_URL}/user/login`;
      axios
        .post(url, this.user)
        .then((response) => {
          if (response.data.token) {
            localStorage.setItem("TOKEN", response.data.token);
            let userLogado = JSON.stringify(response.data);
            localStorage.setItem("userLogado", userLogado);
            setTimeout(() => {
              this.$router.push({
                name: "Home",
              });
            }, 6000);
            setTimeout(() => {
              window.location.reload();
            }, 8500);
          } else {
            this.alert.text = "Por favor verifica os teus dados de acesso!";
          }
        })
        .catch((error) => {
          this.alert.text = "Não foi possivel establecer ligação!";
        });
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
  },
};
</script>

<style scoped>
.custom-font-size {
  font-size: 50px !important;
}

.transicao-btn-enter-active,
.transicao-btn-leave-active {
  transition: opacity 0.5s;
}

.transicao-btn-enter,
.transicao-btn-leave-to {
  opacity: 0;
}

.background-image {
  background-image: url("https://www.pngitem.com/pimgs/m/522-5229044_e-commerce-store-png-transparent-png.png");
  background-size: cover;
  /* adjust as necessary */
  background-position: center center;
  /* adjust as necessary */
  background-repeat: no-repeat;
  /* adjust as necessary */
}

#content {
  margin-top: 70px;
}

.section-title {
  padding-bottom: 0px;
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 170px;
  height: 3px;
  background: #2b42a8;
  bottom: 0;
  left: calc(50% - 50%);
}

.red {
  color: red;
}

.green {
  color: green;
}
</style>
